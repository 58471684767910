import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDown, ChevronUp } from '@videoblocks/react-icons';

interface Props {
  isCollapsed: boolean;
  invalidSelectionMessage: string;
  className?: string;
}

const ArrowIcon = ({
  isCollapsed = false,
  invalidSelectionMessage,
  className = '',
}: Props) => {
  if (invalidSelectionMessage) {
    return null;
  }

  if (isCollapsed) {
    return <ChevronDown className={twMerge('w-3 h-3 m-1.5', className)} />;
  } else {
    return <ChevronUp className={twMerge('w-3 h-3 m-1.5', className)} />;
  }
};

export default ArrowIcon;
