import React, { Component } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { twMerge } from 'tailwind-merge';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { DownloadBottom as DownloadIcon } from '@videoblocks/react-icons';
import { ProgressiveImage } from '@videoblocks/storywind';

import displayEventMediator from '../../../../Events/DisplayEventMediator';
import StockItemDisplayEventFactory from '../../../../Events/StockItemDisplayEventFactory';
import { Folder } from '../../../../Member/Folders/types';
import { selectMemberBins } from '../../../../Member/MemberBins/selectors/foldersSelectors';
import {
  selectHasImagesSubscription,
  selectIsLoggedIn,
  selectPrimarySubscription,
} from '../../../../auth/AuthSelectors';
import { Subscription } from '../../../../auth/AuthTypes';
import ElementDisplayedListener from '../../../../common/ElementDisplayedListener';
import Logger from '../../../../common/Logger';
import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import FavoriteButton from '../../../../common/components/FavoriteButton';
import SignUpForMakerPopover from '../../../../common/components/Popover/SignUpForMakerPopover';
import Popper, {
  Placements,
} from '../../../../common/components/Popper/Popper';
import {
  StockItem,
  StockItemContext,
  StockItemFormat,
} from '../../../../common/types/StockItemTypes';
import {
  convertStockItemContextToOriginArea,
  getPreviewUrl,
  isMouseOverElement,
  isStockItemInAnyFolder,
  sendBeacon,
} from '../../../../common/utils';
import {
  collapseMoreLikeThisDrawer,
  expandMoreLikeThisDrawer,
  fetchCollapsedSetResults,
  setSelectedStockItemOptions,
  updateSearchPage,
} from '../../actions/SearchActions';
import { ImageSelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import {
  selectDrawerIsOpen,
  selectDrawerSelectedItemId,
  selectSearchFeatures,
  selectSearchFilterOptions,
  selectSelectedStockItem,
} from '../../selectors/searchSelectors';
import StockItemCardButtons from './StockItemCardButtons';
import StockItemFormatSelector from './StockItemFormatSelector';
import StockItemIcons from './StockItemIcons';

type Props = {
  stockItem: StockItem;
  stockItemFormats: StockItemFormat[];
  isSelected: boolean;
  searchLogParameters: object;
  minimize: boolean;
  containerResponsiveClassNames: string[];
  hideOnThreeColumnLayout: boolean;
  style: object;
  classNames: string[];
  imgClassNames: string[];
  imgWrapperClassNames: string[];
  shouldShowAddToFavorites: boolean;
  page: number;
  context: StockItemContext;
  stockItemCardContext?: { title: string; position: number };
  shouldStockItemHaveNewFlag: boolean;
  isCollapsedStockItemLoadMoreCard: boolean;
  handleLoadingMoreCollapsedStockItems: () => void;
  shouldShowDownloadButton: boolean;
  downloadButtonTelemetryAction?: () => void;
  makerButtonTelemetryAction?: () => void;
  lazyLoad: boolean;
  isLoggedIn: boolean;
  primarySubscription: Subscription;
  imageSubscription: Subscription;
  formatSelectorVisible: boolean;
  selectedFormatName: string;
  selectedFormatResolution: string;
  setSelectedStockItemOptions: typeof setSelectedStockItemOptions;
  redirectToSignUpLink?: boolean;
  bins: Folder[];
  isMobile: boolean;
  dispatch: ThunkDispatch<any, any, any>;
  drawerIsOpen: boolean;
  drawerSelectedItemId: number;
  selectedSearchFilterOptions: ImageSelectedSearchFilterOptions;
};

type State = {
  isDragging: boolean;
  isHovering: boolean;
  imgOrientationCSSClass: string;
  showDownloadTooltip: boolean;
  showFavoriteTooltip: boolean;
  showMoreLikeThisTooltip: boolean;
  hoverStartTime: number;
  playStartTime: number;
};

class GraphicStockItemCard extends Component<Props, State> {
  containerRef: React.RefObject<HTMLElement>;
  previewImageRef: React.RefObject<HTMLImageElement>;

  state = {
    isDragging: false,
    isHovering: false,
    imgOrientationCSSClass: 'hidden-no-aspect-ratio',
    showDownloadTooltip: false,
    showFavoriteTooltip: false,
    showMoreLikeThisTooltip: false,
    hoverStartTime: null,
    playStartTime: null,
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.previewImageRef = React.createRef();
  }

  static defaultProps = {
    containerResponsiveClassNames: [
      'col-lg-3 ',
      'col-md-4 ',
      'col-sm-4 ',
      'col-xs-10 ',
      'col-xs-offset-1 ',
      'col-lg-offset-0 ',
      'col-md-offset-0 ',
      'col-sm-offset-0',
    ],
    style: {},
    classNames: [],
    imgClassNames: [],
    imgWrapperClassNames: [],
    isCollapsedStockItemLoadMoreCard: false,
    lazyLoad: true,
    downloadButtonTelemetryAction: null,
    makerButtonTelemetryAction: null,
    stockItemCardContext: {},
  };

  componentDidMount() {
    // track drags to tell if a touch is a deliberate or the end of a drag
    this.containerRef.current.addEventListener('touchmove', () => {
      if (!this.state.isDragging) {
        this.setState({
          isDragging: true,
        });
      }
    });

    this.containerRef.current.addEventListener('touchstart', () => {
      if (this.state.isDragging) {
        this.setState({
          isDragging: false,
        });
      }
    });

    ElementDisplayedListener(this.containerRef.current, () => {
      const context = this.props.stockItemCardContext;

      if (context.position) {
        displayEventMediator.push(
          StockItemDisplayEventFactory.displayedStockItem(
            this.props.stockItem,
            context.position
          )
        );
      }
    });

    this.setImgOrientationIfNecessary();
    this.shouldShowMoreLikeThisTooltipByDefault();
  }

  render() {
    const {
      isCollapsedStockItemLoadMoreCard,
      stockItem,
      stockItemFormats,
      style,
    } = this.props;

    return (
      <section
        className={this.getContainerClassNames()}
        data-num-format-options={stockItemFormats.length}
        data-stock-id={stockItem.id}
        ref={this.containerRef}
        onMouseEnter={this.handleIsHovering}
        onMouseLeave={this.handleIsNotHovering}
        style={style}
      >
        {isCollapsedStockItemLoadMoreCard
          ? this.renderCollapsedStockItemLoadMoreCard()
          : this.renderNormalStockItemCard()}
      </section>
    );
  }

  handleIsHovering = () => {
    if (!this.props.formatSelectorVisible) {
      this.setState({
        isHovering: true,
        hoverStartTime: Date.now(),
      });

      if (this.props.page) {
        this.props.dispatch(
          updateSearchPage(this.props.page, this.props.stockItem.id)
        );
      }
    }
  };

  handleIsNotHovering = (e) => {
    /**
     * Click on the favorite button will sometimes trigger
     * a mouseLeave event based on the Popper's positioning,
     * preventing the Popper from appearing. This check blocks
     * that from happening.
     */
    if (e?.target?.className?.includes?.('action-icon')) {
      return;
    }

    this.setState({
      isHovering: false,
      playStartTime: null,
    });
  };

  handleClickAction = (e) => {
    if (!isMouseOverElement(e?.nativeEvent || e, this.containerRef.current)) {
      this.handleIsNotHovering(e);
    }
  };

  renderCollapsedStockItemLoadMoreCard() {
    const {
      stockItem,
      shouldStockItemHaveNewFlag,
      handleLoadingMoreCollapsedStockItems,
    } = this.props;

    return (
      <div
        role="button"
        tabIndex={0}
        onClick={handleLoadingMoreCollapsedStockItems}
        onKeyDown={handleLoadingMoreCollapsedStockItems}
      >
        <div className="box thumbnail faded load-more">
          <StockItemIcons showNewFlag={shouldStockItemHaveNewFlag} />

          <ProgressiveImage
            className="img-responsive progress-background thumbnail-img shrinking main-img"
            src={stockItem.thumbnailUrl}
            alt={stockItem.title}
          />
        </div>

        <h4 className="faded-thumbnail-text">Load More</h4>
      </div>
    );
  }

  renderNormalStockItemCard() {
    const {
      minimize,
      shouldStockItemHaveNewFlag,
      stockItem,
      imgWrapperClassNames,
    } = this.props;

    const { isHovering } = this.state;
    return (
      <>
        <div className="box top-level-li" data-stock-id={stockItem.id}>
          <div
            className={twMerge(
              imgWrapperClassNames,
              'thumbnail-img-wrapper overflow-hidden'
            )}
          >
            <StockItemIcons showNewFlag={shouldStockItemHaveNewFlag} />

            {this.renderStockItemFormatSelector()}

            {this.getDetailLink()}
          </div>

          {!minimize && this.renderButtons()}

          {this.shouldShowCollapsedContentUI() && this.renderBurstEffect()}
        </div>

        {isHovering && this.renderActionButtons()}
      </>
    );
  }

  renderStockItemFormatSelector() {
    if (!this.props.formatSelectorVisible) {
      return;
    }

    const {
      formatSelectorVisible,
      stockItemFormats,
      selectedFormatName,
      selectedFormatResolution,
    } = this.props;

    return (
      <StockItemFormatSelector
        isVisible={formatSelectorVisible}
        stockItemFormats={stockItemFormats}
        selectedFormatName={selectedFormatName}
        selectedFormatResolution={selectedFormatResolution}
        selectedFormatChanged={(selectedFormatName, selectedFormatResolution) =>
          this.handleSelectedFormatChanged(
            selectedFormatName,
            selectedFormatResolution
          )
        }
      />
    );
  }

  handleSelectedFormatChanged = (
    selectedFormatName,
    selectedFormatResolution
  ) => {
    this.props.dispatch(
      setSelectedStockItemOptions({
        id: this.props.stockItem.id,
        selectedFormatName,
        selectedFormatResolution,
      })
    );
  };

  getContainerClassNames() {
    return twMerge(
      this.props.containerResponsiveClassNames,
      this.props.classNames,
      'stock-item-v2 stock-item',
      this.props.isSelected && 'is-selected',
      this.props.formatSelectorVisible && 'download-format-selector-visible',
      this.props.hideOnThreeColumnLayout && 'hidden-sm hidden-md',
      this.shouldShowCollapsedContentUI() && 'collapsed-card'
    );
  }

  handleOnTouchEnd = () => {
    const { isDragging } = this.state;

    if (!isDragging) {
      window.location.href = this.props.stockItem.detailsUrl;
    }
  };

  handleStockItemClick = (e) => {
    e.stopPropagation();
    if (e.target.nodeName === 'IMG') {
      const { stockItem, stockItemCardContext } = this.props;

      const stockItemId = stockItem.id;
      const position = stockItemCardContext && stockItemCardContext.position;
      const urlPath = '/api/image/stockItemClickEvent/details';
      const beacon = sendBeacon(urlPath, stockItemId, position);

      if (!beacon) {
        Logger.produceDetailsClickEvent(stockItem, position);
      }
    }
  };

  getDetailLink() {
    const imgCSSClasses = this.props.imgClassNames.concat([
      'main-img',
      this.state.imgOrientationCSSClass,
    ]);

    return (
      <a
        href={this.props.stockItem.detailsUrl}
        onTouchEnd={this.handleOnTouchEnd}
        onClick={this.handleStockItemClick}
      >
        <ProgressiveImage
          src={this.getThumbnailImageUrl()}
          alt={this.props.stockItem.title}
          className={twMerge(imgCSSClasses)}
          onLoad={this.setImgOrientationIfNecessary}
          ref={this.previewImageRef}
          shouldLazyLoad={this.props.lazyLoad}
        />
      </a>
    );
  }

  renderButtons() {
    const { formatSelectorVisible } = this.props;

    if (!formatSelectorVisible) {
      return null;
    }

    return (
      <div className="controls">
        <StockItemCardButtons
          onPrimaryActionClicked={this.getPrimaryClickAction()}
          onSecondaryActionClicked={this.hideDownloadButtons()}
          showCancelLabel={this.props.formatSelectorVisible}
          redirectToSignUpLink={this.props.redirectToSignUpLink}
          detailsUrl={this.props.stockItem.detailsUrl}
        />
      </div>
    );
  }

  hideDownloadButtons() {
    return (event) => {
      event.preventDefault();

      this.props.dispatch(
        setSelectedStockItemOptions({
          formatSelectorVisible: false,
        })
      );

      this.setState({
        isHovering: true,
      });
    };
  }

  renderActionButtons() {
    return (
      <div className="action-buttons right-3 top-2 space-y-1">
        {this.getDownloadButton()}
        {this.props.shouldShowAddToFavorites && this.getFavoriteButton()}
        {this.shouldShowMakerButton() && this.getMakerButton()}
        {this.shouldShowCollapsedContentUI() && this.getSimilarButton()}
      </div>
    );
  }

  getDownloadButton() {
    const { shouldShowDownloadButton, downloadButtonTelemetryAction } =
      this.props;

    if (shouldShowDownloadButton === false) {
      return;
    }

    const fireDownloadTelemetryActionFromParent = () => {
      downloadButtonTelemetryAction?.();
    };

    return (
      <div>
        <Popper
          placement={Placements.Left}
          popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
          showContent={this.state.showDownloadTooltip}
          triggerElement={(referenceElement) => (
            <div
              role="button"
              tabIndex={0}
              className="action-wrapper download-button action-download cursor-pointer text-white"
              data-cy="download-button"
              ref={referenceElement}
              onClick={this.getPrimaryClickAction()}
              onKeyDown={this.getPrimaryClickAction}
              onMouseEnter={() => this.setState({ showDownloadTooltip: true })}
              onFocus={() => this.setState({ showDownloadTooltip: true })}
              onMouseLeave={() => this.setState({ showDownloadTooltip: false })}
              onBlur={() => this.setState({ showDownloadTooltip: false })}
            >
              <DownloadIcon
                onClick={() => fireDownloadTelemetryActionFromParent()}
                className="action-icon fill-current"
              />
            </div>
          )}
        >
          <div id="tooltip-download">
            {this.isASubscriptionlessUser() ? 'Download Preview' : 'Download'}
          </div>
        </Popper>
      </div>
    );
  }

  isASubscriptionlessUser() {
    return this.props.isLoggedIn && !this.props.imageSubscription;
  }

  getFavoriteButton() {
    const bins = this.props.bins ? Object.values(this.props.bins) : [];

    const tooltipText = isStockItemInAnyFolder(bins, this.props.stockItem)
      ? 'Added to Folder'
      : 'Add to Folder';

    return (
      <div>
        <Popper
          placement={Placements.Left}
          popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
          showContent={this.state.showFavoriteTooltip}
          triggerElement={(referenceElement) => (
            <div
              className="action-wrapper"
              ref={referenceElement}
              onMouseEnter={() => this.setState({ showFavoriteTooltip: true })}
              onMouseLeave={() => this.setState({ showFavoriteTooltip: false })}
            >
              <FavoriteButton
                onButtonClick={() =>
                  this.setState({ showFavoriteTooltip: false })
                }
                onClickAction={this.handleClickAction}
                stockItem={this.props.stockItem}
                stockItemFormats={this.props.stockItemFormats}
                telemetryKey="search"
                iconClassName="p-1.5 w-5 h-5"
              />
            </div>
          )}
        >
          <div
            id="tooltip-favorite"
            className="whitespace-nowrap"
          >{`${tooltipText}`}</div>
        </Popper>
      </div>
    );
  }

  shouldShowMakerButton() {
    const { contentType } = this.props.stockItem;
    return (
      (contentType === 'photo' || contentType === 'snapshot') &&
      !this.props.isMobile
    );
  }

  getMakerButton() {
    const { stockItem, makerButtonTelemetryAction } = this.props;

    return (
      <SignUpForMakerPopover
        mediaType="image"
        iconClassName="fill-current text-white"
        stockItemId={stockItem.id}
        onClickAction={() => makerButtonTelemetryAction?.()}
      />
    );
  }

  getMakerUrl(base, stockItemId) {
    return base + '?imageIds=' + stockItemId;
  }

  getSimilarButton() {
    return (
      <div>
        <Popper
          placement={Placements.Left}
          popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
          showContent={this.state.showMoreLikeThisTooltip}
          triggerElement={(referenceElement) => (
            <div
              className="action-wrapper download-button action-download"
              ref={referenceElement}
              onMouseEnter={() =>
                this.setState({ showMoreLikeThisTooltip: true })
              }
              onMouseLeave={() =>
                this.setState({ showMoreLikeThisTooltip: false })
              }
            >
              <div className="action-wrapper similar-button">
                <ProgressiveImage
                  alt="More like this icon"
                  className="action-icon w-8 h-8"
                  onClick={this.getSimilarContentAction}
                  src="/assets/common/images/icons/stock-item-cards/sb-video-similar.svg"
                />
              </div>
            </div>
          )}
        >
          <div id="tooltip-similar" className="whitespace-nowrap">
            More Like This
          </div>
        </Popper>
      </div>
    );
  }

  shouldShowMoreLikeThisTooltipByDefault() {
    const hasSeenMoreLikeThisTooltip = cookie.load('morelikethis');
    const domain = window && window.location && '.' + window.location.hostname;

    if (!hasSeenMoreLikeThisTooltip) {
      cookie.save('morelikethis', true, { path: '/', domain: domain });
      this.setState({ showMoreLikeThisTooltip: true });
    }

    this.setState({ showMoreLikeThisTooltip: false });
  }

  setImgOrientationIfNecessary = () => {
    // Don't do anything if the stock item already has an aspect ratio
    if (this.stockItemHasAspectRatio()) {
      return;
    }

    const img = this.previewImageRef?.current;

    if (img && img.complete && img.clientHeight && img.clientWidth) {
      const aspectRatio = img.clientWidth / img.clientHeight;

      this.setState({
        imgOrientationCSSClass: aspectRatio <= 1 ? 'portrait' : 'landscape',
      });
    }
  };

  stockItemHasAspectRatio() {
    const item = this.props.stockItem;
    return 'aspectRatio' in item && item.aspectRatio;
  }

  useSmallerThumbnailImage() {
    return (
      this.stockItemHasAspectRatio() && this.props.stockItem.aspectRatio < 2.0
    );
  }

  getThumbnailImageUrl() {
    // Let's use the most ideal size, if multiple are available
    if (
      this.props.stockItem.thumbnailUrl &&
      this.props.stockItem.smallPreviewUrl
    ) {
      return this.useSmallerThumbnailImage()
        ? this.props.stockItem.thumbnailUrl
        : this.props.stockItem.smallPreviewUrl;
    } else {
      // If we only have one size, we'll pick the first one that's available
      return (
        this.props.stockItem.smallPreviewUrl ||
        this.props.stockItem.thumbnailUrl
      );
    }
  }

  getPrimaryClickAction() {
    if (this.isASubscriptionlessUser()) {
      return () => {
        Telemetry.increment('preview.downloaded.stockItemCard');
        window.location.href = getPreviewUrl(
          this.props.stockItem.id,
          this.props.stockItem.contentClass
        );
      };
    } else if (this.getRedirectPage()) {
      return (event) => {
        event.preventDefault();
        this.logPrimaryClickAction();
        window.location.href = this.getRedirectPage();
      };
    }

    return this.getDefaultPrimaryClickAction();
  }

  getDefaultPrimaryClickAction() {
    return (event) => {
      this.logPrimaryClickAction();
      if (!this.overrideClickActionsForRedirect()) {
        event.preventDefault();
        if (
          !this.props.formatSelectorVisible &&
          this.props.stockItemFormats.length >= 2
        ) {
          this.primaryActionUpdateSelectedStockItemOption();
        } else {
          const selectedFormat = this.getSelectedFormat();
          this.primaryActionDownloadItem(selectedFormat);
        }
      }
    };
  }

  getSelectedFormat() {
    const { stockItemFormats, selectedFormatName, selectedFormatResolution } =
      this.props;

    return stockItemFormats.length >= 2
      ? stockItemFormats.find((stockItemFormat) => {
          return (
            stockItemFormat.formatName === selectedFormatName &&
            stockItemFormat.resolution === selectedFormatResolution
          );
        })
      : this.props.stockItemFormats[0];
  }

  overrideClickActionsForRedirect() {
    return this.props.redirectToSignUpLink;
  }

  primaryActionUpdateSelectedStockItemOption() {
    this.setState({
      isHovering: false, // Disable hover state when format selector is visible
    });

    this.props.dispatch(
      setSelectedStockItemOptions({
        id: this.props.stockItem.id,
        formatSelectorVisible: true,
        selectedFormatName: this.props.stockItemFormats[0].formatName,
        selectedFormatResolution: this.props.stockItemFormats[0].resolution,
      })
    );
  }

  logPrimaryClickAction() {
    const { stockItem, stockItemCardContext } = this.props;
    const stockItemId = stockItem.id;
    const position = stockItemCardContext && stockItemCardContext.position;
    const urlPath = '/api/image/stockItemClickEvent/download';
    const beacon = sendBeacon(urlPath, stockItemId, position);

    if (!beacon) {
      Logger.produceDownloadClickEvent(stockItem, position);
    }
  }

  async primaryActionDownloadItem(selectedFormat) {
    const { primarySubscription, imageSubscription, context } = this.props;

    const originArea = convertStockItemContextToOriginArea(context);
    const params = originArea ? `?origin_area=${originArea}` : ``;

    Telemetry.increment('download.clicked.stockItemCard');
    const dataBridge = await SiteConstants.buildInstance().getDataBridge();
    dataBridge.downloadItem(
      selectedFormat.downloadUrl + params,
      selectedFormat.downloadAjaxUrl + params,
      this.props.searchLogParameters,
      this.props.dispatch,
      // NOTE: used to provide data for Intercom in Starter Plan A/B Test, remove after test if possible
      primarySubscription || imageSubscription
    );
  }

  getSimilarContentAction = () => {
    if (
      this.props.drawerIsOpen &&
      this.props.drawerSelectedItemId === this.props.stockItem.id
    ) {
      this.props.dispatch(collapseMoreLikeThisDrawer());
    } else {
      const { collapsedSetId, id } = this.props.stockItem;
      const updateFields = {
        collapsedSetId,
        id,
        excludeStockItemIds: this.props.stockItem.id,
        page: 1,
      };
      const newSearchOptions =
        this.props.selectedSearchFilterOptions.update(updateFields);
      this.props.dispatch(fetchCollapsedSetResults(newSearchOptions));
      this.props.dispatch(expandMoreLikeThisDrawer({ initialItemId: id }));
    }
  };

  getRedirectPage() {
    let pageToRedirectTo = null;

    if (!this.props.imageSubscription) {
      pageToRedirectTo = this.props.stockItem.detailsUrl;
    }

    return pageToRedirectTo;
  }

  renderBurstEffect = () => (
    <>
      <div className="first-shadow" />
      <div className="second-shadow" />
    </>
  );

  shouldShowCollapsedContentUI() {
    // NOTE -- The array of related Items ALWAYS INCLUDES stockItem itself.
    //         So if there is only 1 Item in the collapseSetId array,
    //         then we don't have any related Items.
    //
    // If there are only 2 Items in the collapseSetId array,
    //      then we only have one other related Item,
    //      and we should NOT SHOW any UI related to Collapsed Content.
    return (
      this.props.stockItem.numCollapses > 2 &&
      this.props.context === StockItemContext.SEARCH
    );
  }
}

function mapStateToProps(state, props) {
  const selectedStockItem = selectSelectedStockItem(state);
  const features = selectSearchFeatures(state);
  const isStockItemSelected = selectedStockItem.id === props.stockItem.id;

  return {
    formatSelectorVisible: isStockItemSelected
      ? selectedStockItem.formatSelectorVisible
      : false,
    selectedFormatName: isStockItemSelected
      ? selectedStockItem.selectedFormatName
      : null,
    selectedFormatResolution: isStockItemSelected
      ? selectedStockItem.selectedFormatResolution
      : null,
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    signUpLink: features.signUpLink,
    redirectToSignUpLink: features.redirectToSignUpLink,
    bins: selectMemberBins(state),
    drawerIsOpen: selectDrawerIsOpen(state),
    drawerSelectedItemId: selectDrawerSelectedItemId(state),
    isLoggedIn: selectIsLoggedIn(state),
    primarySubscription: selectPrimarySubscription(state),
    imageSubscription: selectHasImagesSubscription(state),
  };
}

// @ts-ignore
export default connect(mapStateToProps)(GraphicStockItemCard);
