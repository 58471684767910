import { StockItemHoverEvent } from '@videoblocks/events-ts/lib/storyblocks/content/StockItemHoverEvent';

import events from './Events';
import ShallowStockItemFactory from './ShallowStockItemFactory';

export default class StockItemHoverEventFactory {
  /**
   *
   * @param {object} stockItem - object with information about the stock item
   * @param {number} hoverStartMs - Timestamp of when the hover began
   * @param {number} playStartTimeMs - Timestamp of when the video began
   * @param {number} timesLooped - Integer value of number of times looped
   * @param {number} playEndTimeStamp - Integer value of the video's timestamp
   * @param {number} [now] - Integer value of the current timestamp
   * @param {number} timesLooped - Integer value of the number of times the video has looped
   * @param {number} accumulatedPlayDurationMs - Total time the video has been playing in seconds
   * @returns {StockItemHoverEvent}
   */
  static create(
    stockItem,
    hoverStartMs,
    playStartTimeMs,
    playEndTimeStamp,
    timesLooped,
    accumulatedPlayDurationMs,
    now = Date.now(),
    originArea = null,
    storyboardRequestId = null,
    storyboardSceneRequestId = null
  ) {
    const event = new StockItemHoverEvent();
    const playDuration = playStartTimeMs ? now - playStartTimeMs : 0;

    event.meta = events.meta();
    event.device = events.device();
    event.user = events.user();
    event.searchIdentifiers = events.getSearchIdentifiers();

    event.stockItem = ShallowStockItemFactory.create(stockItem);

    event.originArea = originArea;
    event.storyboardRequestId = storyboardRequestId;
    event.storyboardSceneRequestId = storyboardSceneRequestId;

    event.hoverStart = hoverStartMs;
    event.hoverEnd = now;
    event.playEnd = Math.floor(playEndTimeStamp);
    event.timesLooped = timesLooped;
    if (playStartTimeMs) {
      // Could possibly just be a ternary for 0 or playEnd...save off into state or something?
      event.playStart = Math.ceil(
        event.playEnd - playDuration / 1000 + timesLooped * stockItem.duration
      );
    } else {
      event.playStart = 0;
    }

    event.urls = events.urls();

    return event;
  }
}
