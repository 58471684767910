import StockItemFormatSelector, {
  StockItemFormatSelectorProps,
} from './StockItemFormatSelector';

interface Props extends StockItemFormatSelectorProps {
  downloadPermissions?: Record<string, boolean>;
}

class VideoStockItemFormatSelector extends StockItemFormatSelector<Props> {
  componentDidMount() {
    const noFourKDownloadPermission =
      this.props.downloadPermissions &&
      this.props.downloadPermissions.fourK === false;

    const isFourK = this.props.stockItemFormats[0]['quality'] === '4K';
    const isHD = this.props.stockItemFormats[0]['quality'] === 'HD';

    if (noFourKDownloadPermission || isHD) {
      this.selectHDMP4Option();
    } else if (isFourK) {
      this.select4KMP4Option();
    }
  }

  selectHDMP4Option() {
    const HDMP4Option = this.props.stockItemFormats.find(
      (format) => format.formatName === 'HDMP4'
    );

    if (HDMP4Option) {
      this.props.selectedFormatChanged(
        HDMP4Option.formatName,
        HDMP4Option.resolution
      );
    }
  }

  select4KMP4Option() {
    const FourKMP4Option = this.props.stockItemFormats.find(
      (format) => format.formatName === '4KMP4'
    );

    if (FourKMP4Option) {
      this.props.selectedFormatChanged(
        FourKMP4Option.formatName,
        FourKMP4Option.resolution
      );
    }
  }
}

export default VideoStockItemFormatSelector;
