import React, { Component } from 'react';
import { twMerge } from 'tailwind-merge';

import { StockItemFormat } from '../../../../common/types/StockItemTypes';

export interface StockItemFormatSelectorProps {
  isVisible: boolean;
  stockItemFormats: StockItemFormat[];
  selectedFormatName: string;
  selectedFormatChanged: (formatName: string, resolution: string) => void;
  selectedFormatResolution: string;
}

class StockItemFormatSelector<
  P extends StockItemFormatSelectorProps = StockItemFormatSelectorProps,
> extends Component<P> {
  render() {
    return (
      <div
        className={twMerge(
          'download-format-selector bg-black bg-opacity-60',
          this.props.isVisible && 'view'
        )}
      >
        <div
          className={twMerge(
            'download-formats',
            this.props.stockItemFormats.length > 2 && 'download-formats-long'
          )}
        >
          <strong>Choose a format:</strong>
          {this.renderFormatsList()}
        </div>
      </div>
    );
  }

  renderFormatsList() {
    return this.props.stockItemFormats.map((stockItemFormatDetails) => {
      const id = `stockItemFormat${stockItemFormatDetails.formatName}`;
      return (
        <div
          className="download-format-option"
          key={stockItemFormatDetails.formatName}
        >
          <input
            data-cy={id}
            id={id}
            type="radio"
            className="text-white rounded-full bg-white"
            value={stockItemFormatDetails.formatName}
            checked={
              this.props.selectedFormatName ==
                stockItemFormatDetails.formatName &&
              this.props.selectedFormatResolution ==
                stockItemFormatDetails.resolution
            }
            onChange={() =>
              this.props.selectedFormatChanged(
                stockItemFormatDetails.formatName,
                stockItemFormatDetails.resolution
              )
            }
            data-extension={stockItemFormatDetails.fileExtension || ''}
          />
          <label htmlFor={id}>
            {this.getTitleForStockFormat(stockItemFormatDetails)}
          </label>
        </div>
      );
    });
  }

  getTitleForStockFormat(stockItemFormatDetails) {
    let label = stockItemFormatDetails.label;
    if (stockItemFormatDetails.codec) {
      label += ` (${stockItemFormatDetails.codec})`;
    }

    return `${label} - ${stockItemFormatDetails.filesize}`;
  }
}

export default StockItemFormatSelector;
